import {Vue} from "vue-class-component/dist/vue-class-component"
import Catalogue from "@/models/Catalogue"
import {getModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import ConstantTool from "@/services/tool/ConstantTool"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import Response from "@/models/responses/Response";
import axios from "axios";

export default class CatalogueService {

    static async getCatalogue(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/catalogues/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se ha podido obtener el catalogo.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getCatalogue2(id: number): Promise<Response<Catalogue>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/catalogues/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
            return Promise.resolve({ result: catalogue })
        } catch (e) { return Promise.reject(e) }
    }

    static async getCatalogues(page: number, size: number, search: Nullable<string>, active: Nullable<boolean>): Promise<Response<Catalogue[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/catalogues", {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { page, size, search, active }
            })
            const catalogues = JsonTool.jsonConvert.deserializeArray(response.data, Catalogue)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: catalogues, xTotalCount  })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async postCatalogue(component: Vue, catalogue: Catalogue, document: File): Promise<Response<Catalogue>> {

        let formData: FormData = new FormData()
        formData.set("name", catalogue.name!)
        formData.set("document", document!)

        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/catalogues", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
            getModule(SnackbarModule).makeToast("El catalogo ha sido creado con exito.")
            return Promise.resolve({ result: catalogue })
        } catch (e) {
            getModule(SnackbarModule).makeToast("Ha ocurrido un error creando el catalogo.")
            return Promise.reject(e)
        }
    }

    static async updateCatalogue(component: Vue, id: number, catalogue: Catalogue) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/catalogues/" + id, catalogue, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            // @ts-ignore
            component.catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
            getModule(SnackbarModule).makeToast("El catalogo se ha actualizado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Ha ocurrido un error actualizando el catalogo.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async activateCatalogue(component: Vue, id: number, active: boolean) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/catalogues/" + id + "/activate", {}, {
                headers: { Authorization: getModule(SessionModule).session.token },
                params: { active }
            })
            // @ts-ignore
            component.catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
            getModule(SnackbarModule).makeToast("El catalogo se ha actualizado con exito.")
        } catch (e) {
            getModule(SnackbarModule).makeToast("Ha ocurrido un error actualizando el catalogo.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

}