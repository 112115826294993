import {JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";
import Document from "@/models/Document";
import DateTimeConverter from "@/services/converters/DateTimeConverter";
import {DateTime} from "luxon";

@JsonObject("Catalogue")
export default class Catalogue extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("document", Document, true)
    document?: Document = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("activatedAt", DateTimeConverter, true)
    activatedAt?: DateTime = undefined

}